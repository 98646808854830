import React from 'react'

import { useStateContext } from '../functions/ContextProvider'

export default function Perfil() {

    const { user, setUser } = useStateContext();

    const handleChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });

        // Hacer https put a la API para actualizar el usuario

        

    }

    return (
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-6'>
            <section className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                <div className="grid gap-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="nombre">Nombre</label>
                    <input
                        type="text"
                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        name="nombre"
                        placeholder="Nombre"
                        required=""
                        value={user.nombre}
                        onChange={handleChange}
                    />
                </div>
                <div className="grid gap-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="apellidos">Apellidos</label>
                    <input
                        type="text"
                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        name="apellidos"
                        placeholder="Apellidos"
                        required=""
                        value={user.apellidos}
                        onChange={handleChange}
                    />
                </div>
                <div className="grid gap-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="apodo">Apodo</label>
                    <input
                        type="text"
                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        name="apodo"
                        placeholder="Apodo"
                        required=""
                        value={user.apodo}
                        onChange={handleChange}
                    />
                </div>
                <div className="grid gap-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="fecha_nacimiento">Fecha Nacimiento</label>
                    <input
                        type="date"
                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        name="fecha_nacimiento"
                        placeholder="Fecha Nacimiento"
                        required=""
                        value={user.fecha_nacimiento}
                        onChange={handleChange}
                    />
                </div>
            </section>
            <hr/>
            <section className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                <div className="grid gap-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="numero">Numero</label>
                    <input
                        type="number"
                        step={1}
                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        name="numero"
                        placeholder="Numero"
                        required=""
                        value={user.numero}
                        onChange={handleChange}
                    />
                </div>
                <div className="grid gap-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="posicion">Posicion</label>
                    <input
                        type="text"
                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        name="posicion"
                        placeholder="Posicion"
                        required=""
                        value={user.posicion}
                        onChange={handleChange}
                    />
                </div>
            </section>
        </div>
    )
}
